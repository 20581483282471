<template>
  <b-overlay :show="show2" rounded="sm" no-fade>
  <div class="m-2">
    <div align="center">
      <h3>AP STATUS</h3>

      <span v-for="(value, name) in groub">
        <b-badge
          v-if="name == 'online'"
          class="mr-1"
          variant="success"
          @click="filter = name"
        >
          {{ name }}: {{ value.length }}
        </b-badge>
        <b-badge
          v-else-if="name == 'offline'"
          class="mr-1"
          variant="danger"
          @click="filter = name"
        >
          {{ name }}: {{ value.length }}
        </b-badge>
        <b-badge v-else variant="info" class="mr-1" @click="filter = name">
          {{ name }}: {{ value.length }}
        </b-badge>
      </span>
    </div>
    <!-- <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-chats scroll-area"
      style="height: 525px;"
    >
      <div v-for="(item, index) in data" :key="index">

        {{ item.mac }}   สถานะ:
        <b-badge v-if="item.status=='Online' || item.status=='online'"
        variant="success"
      >
      online
      </b-badge>
        <b-badge v-else-if="item.status=='Offline' || item.status=='offline'"
        variant="danger"
      >
      offline
      </b-badge>
        <b-badge v-else
        variant="info"
      >
      {{ item.status }}
      </b-badge>
        <p> ที่ {{ item.title }} 
            <span v-if="item.description">({{ item.description }} )</span> 
            ตำบล {{ item.district == "" ? item.district : "-" }} 
            อำเภอ {{ item.amphoe == "" ? item.amphoe : "-" }} 
            จังหวัด {{ item.province == "" ? item.province : "-" }}
        </p>

        <hr />
      </div>
    </vue-perfect-scrollbar> -->

    <b-card-body>
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      <br />
      จำนวนทั้งหมด {{ totalRows }} ตัว
    </b-card-body>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-chats scroll-area"
      style="height: 600px;"
    >
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(status)="data" >
          <div @click="detail(data.item.mac)">
            <b-badge
            v-if="data.item.status == 'Online' || data.item.status == 'online'"
            variant="success"
          >
            online
          </b-badge>
          <b-badge
            v-else-if="
              data.item.status == 'Offline' || data.item.status == 'offline'
            "
            variant="danger"
          >
            offline
          </b-badge>
          <b-badge v-else variant="info">
            {{ data.item.status }}
          </b-badge>
          </div>
        </template>

        <template #cell(title)="data" >
          <p @click="detail(data.item.mac)">
            {{ data.item.mac }} ที่ {{ data.item.title }}
            <span v-if="data.item.description"
              >({{ data.item.description }} )</span
            >
            ตำบล {{ data.item.district == "" ? data.item.district : "-" }} อำเภอ
            {{ data.item.amphoe == "" ? data.item.amphoe : "-" }} จังหวัด
            {{ data.item.province == "" ? data.item.province : "-" }}
          </p>
        </template>
      </b-table>
    </vue-perfect-scrollbar>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</b-overlay>
</template>

<script>
import api from "@/api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardBody,
  BProgress,
  BFormGroup,
  BImg,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BInputGroupAppend,
  BButton,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
export default {
  components: {
    VuePerfectScrollbar,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardBody,
    BProgress,
    BBadge,
    BFormGroup,
    BImg,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTable,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BButton,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      show2: true,
      data: [],
      groub: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
      },
      filterOn: [],
      fields: [
        { key: "title", label: "venue" },
        { key: "status", label: "status", sortable: true },
      ],
      /* eslint-disable global-require */
      items: [],
      perPage: 100,
      pageOptions: [20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    detail(mac){
      console.log('mac :>> ', mac);
      this.$router.push({ name: 'ap-reports-detail', query: { mac: mac } })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loadData() {
      this.show2 = true
      const params = {
        status: "all",
      };
      console.log("params :>> ", params);
      api
        .get("ap_status", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.data = response.data.data;
          this.items = response.data.data;
          this.totalRows = response.data.total;
          this.groub = response.data.result;
          this.show2 = false
        })
        .catch((error) => {this.show2 = false});
    },
  },
};
</script>

<style lang="scss" scoped></style>
